import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/index.vue";
import Login from "@/views/login.vue";
import HomePage from "@/views/HomePage.vue";
import OrderPage from "@/views/OrderPage.vue";
import MyPage from "@/views/MyPage.vue";
import hotGoods from "@/views/hotGoods.vue";
import hotGoodsDetails from "@/views/hotGoodsDetails.vue";
import submitOrder from "@/views/submitOrder.vue";
import Address from "@/views/Address.vue";
import orderDetails from "@/views/orderDetails.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
    redirect: "/HomePage",
    children: [
      {
        path: "/HomePage",
        name: "HomePage",
        component: HomePage,
      },
      {
        path: "/OrderPage",
        name: "OrderPage",
        component: OrderPage,
      },
      {
        path: "/MyPage",
        name: "MyPage",
        component: MyPage,
      },
    ],
  },
  {
    path: "/hotGoods",
    name: "hotGoods",
    component: hotGoods,
  },
  {
    path: "/hotGoodsDetails/:id",
    name: "hotGoodsDetails",
    component: hotGoodsDetails,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/submitOrder",
    name: "submitOrder",
    component: submitOrder,
  },
  {
    path: "/Address",
    name: "Address",
    component: Address,
  },
  {
    path: "/orderDetails",
    name: "orderDetails",
    component: orderDetails,
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
